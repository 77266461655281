<template>

	<div class="user">
		

        <h4>我的定制</h4>
		
		 <div class="couitem">
            <table border="1" cellpadding="0" cellspacing="0" >
                <tr>
                    <th>需求单号</th>
                    <th>提交时间</th>
                    <th>出发地</th>
                    <th>目的地</th>

                    <th>预计出游日期</th>
                    <th>游玩天数</th>
                    <th>出游人数</th>
                    <th>联系人</th>
                    <th>手机号</th>
                    <th>微信号</th>
                </tr>
               <tr v-for="(item,i) in list" :key="i">
              
                    <td>{{item.order_sn}}</td>
                    <td>{{item.create_date}}</td>
                    <td>{{item.origin}}</td>
                    <td>{{item.destination}}</td>
                     <td>{{item.travel_date}}</td>
                    <td>{{item.days}}</td>
                    <td>{{item.children_number+item.adults_number}}</td>
                    <td>{{item.contact}}</td>
                    <td>{{item.phone}}</td>
                    <td>{{item.wechat_number}}</td>
                </tr>
                
            </table>
        </div>
	</div>

</template>

<script>
import { mycustomListAPI} from "@/api/user";
export default {

  data () {
    return {
      sign: 1,
      list:[],
      obj:{}

    }
  },
  created () {
    this.getList()
  },

  methods: {
   
   
    getList(){
		  mycustomListAPI().then(res=>{
			  this.list=res.cateList
        // this.obj=res.order_member
		  })
	  }
  }
}
</script>

<style lang="scss" scoped="scoped">

	.user{
		padding: 5px 20px 20px;
		background: #ffffff;
        min-height: 600px;
		
        h4{
            color: #333333;
           margin-top: 20px;
        }
		
		table{
			width: 100%;
			border-color:#fcfcfc; 
			margin-top: 20px;
			th{
				background: #eaeaea;
				line-height: 30px;
				// width: 25%;
				font-size: 13px;
                color: #666666;
			}
			td{
				text-align: center;
				line-height: 40px;
				font-size: 13px;
			}
			td:nth-child(4){
				color: #FF7C00	
			}
		}
	
	}

</style>
